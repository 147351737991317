import { GET, POST, DELETE, PATCH } from "@/utils/axios";

/**
  PROJECT APIs
 */
export const projectBySearch = async (search) =>
  await GET(`/project/list/${search}`, { useAuthorization: true });

/**
  PROJECT-BOARD APIs
 */
//get all boards
export const userBoards = async () =>
  await GET(`/project-board/userboards`, { useAuthorization: true });

export const removeUserBoard = async (id) =>
  await DELETE(`/project-board/${id}`, { useAuthorization: true });

export const getBoard = async (id) =>
  await GET(`/project-board/${id}`, { useAuthorization: true });

export const getBoardStats = async (id, data) =>
  await POST(`/project-board/${id}/stats`, data, { useAuthorization: true });

export const getBoardCharts = async (id, data) =>
  await POST(`/project-board/${id}/charts`, data, { useAuthorization: true });

export const createBoard = async (data) =>
  await POST("/project-board/create", data, { useAuthorization: true });

export const updateBoard = async (id, data) =>
  await POST(`/project-board/${id}`, data, { useAuthorization: true });

export const saveUpdatedBoard = async (data) =>
  await POST("/project-board/remove-card", data, { useAuthorization: true });

export const saveBoardFilters = async (data, boardId) =>
  await POST(`/project-board/${boardId}`, data, { useAuthorization: true });

/**
  SURVEY APIs
 */
// get survey question
export const surveyFilters = async (survey) =>
  await GET(`/survey/question-options?survey=${survey}`, {
    useAuthorization: true,
  });

/**
  user-details APIs
 */
export const userStats = async (data) =>
  await POST("/user-details/project-board-userbase-stats", data, {
    useAuthorization: true,
  });

export const userGraph = async (data) =>
  await POST("/user-details/proejct-board-userbase-graph", data, {
    useAuthorization: true,
  });
// get selected survey questions
export const surveysQuestions = async (data) =>
  await POST(`/question/board/surveys-questions`, data, {
    useAuthorization: true,
  });
// field workborad APIS
export const createFieldworkBoard = async (data) =>
  await POST("/fieldwork-board", data, { useAuthorization: true });

export const userFieldworkBoards = async () =>
  await GET(`/fieldwork-board/user-fieldwork-boards`, {
    useAuthorization: true,
  });

export const removeUserFieldWorkBoard = async (id) =>
  await DELETE(`/fieldwork-board/${id}`, { useAuthorization: true });

export const cardsStats = async (id) =>
  await GET(`/fieldwork-board/${id}/stats`, { useAuthorization: true });

export const surevyProductStats = async (id) =>
  await GET(`/fieldwork-board/${id}/products/stats`, {
    useAuthorization: true,
  });

export const totalCompleteStats = async (id) =>
  await GET(`/fieldwork-board/${id}/total-complete/stats`, {
    useAuthorization: true,
  });

export const surevyProgressStats = async (id) =>
  await GET(`/fieldwork-board/${id}/surveys/stats`, {
    useAuthorization: true,
  });

export const projectProducts = async (id, data) =>
  await POST(`/fieldwork-board/${id}/products`, data, {
    useAuthorization: true,
  });

export const currentBoard = async (id) =>
  await GET(`/fieldwork-board/${id}`, {
    useAuthorization: true,
  });

export const projectScreenersQuestions = async (id, data) =>
  await POST(`/project/${id}/screeners-questions`, data, {
    useAuthorization: true,
  });

export const projectTastingsQuestions = async (id, data) =>
  await POST(`/project/${id}/tastings-questions`, data, {
    useAuthorization: true,
  });

export const updateFieldWorkBoard = async (id, data) =>
  await POST(`/fieldwork-board/${id}/update-quota-questions`, data, {
    useAuthorization: true,
  });

export const quotaQuestionsStats = async (id) =>
  await GET(`/fieldwork-board/${id}/quota-charts-stats`, {
    useAuthorization: true,
  });
// client fw board APIs
export const createClientFwBoard = async (data) =>
  await POST("/client-fw-board", data, { useAuthorization: true });

export const clientfwBoard = async (id) =>
  await GET(`/client-fw-board/${id}`, {
    useAuthorization: true,
  });
// get client fw board by Fw board id
export const clientfwBoardByFwBoardId = async (id) =>
  await GET(`/client-fw-board/fw-board/${id}`, {
    useAuthorization: true,
  });

// update client fw board
export const updateClientFwBoard = async (id, data) =>
  await PATCH(`/client-fw-board/${id}`, data, {
    useAuthorization: true,
  });

export const projectSurveysById = async (projectId) =>
  await GET(`/project/${projectId}/surveys`, { useAuthorization: true });

export const updateBoardSurveys = async (id, data) =>
  await POST(`/fieldwork-board/${id}/update-board-surveys`, data, {
    useAuthorization: true,
  });
