import { createSlice } from "@reduxjs/toolkit";

const fieldworkBoardSlice = createSlice({
  name: "fieldworkBoards",
  initialState: {
    isLoading: false,
    dataSource: [],
    cardStatsLoading: false,
    cardsStatsData: {},
    surveyProductLoading: false,
    surveyProductData: {},
    totalCompleteLoading: false,
    totalCompleteData: [],
    surveyProgressLoading: false,
    surveyProgressData: {},
    currentBoardLoading: false,
    currentBoardData: {},

    questionsLoading: false,
    questionsData: [],
    quotaQuestionsStatsLoading: false,
    quotaQuestionsStatsData: [],

    clientFwBoardLoading: false,
    clientFwBoardData: {},
  },
  reducers: {
    setIsFieldworkLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setFieldworkDataSource: (state, action) => {
      state.dataSource = action.payload;
    },
    setIsCardStatsLoading: (state, action) => {
      state.cardStatsLoading = action.payload;
    },
    setCardsStatsData: (state, action) => {
      state.cardsStatsData = action.payload;
    },
    setIsSurveyProductLoading: (state, action) => {
      state.surveyProductLoading = action.payload;
    },
    setSurveyProductData: (state, action) => {
      state.surveyProductData = action.payload;
    },
    setIsTotalCompleteLoading: (state, action) => {
      state.totalCompleteLoading = action.payload;
    },
    setTotalCompleteData: (state, action) => {
      state.totalCompleteData = action.payload;
    },
    setIsSurveyProgressLoading: (state, action) => {
      state.surveyProgressLoading = action.payload;
    },
    setSurveyProgressData: (state, action) => {
      state.surveyProgressData = action.payload;
    },
    setCurrentBoardLoading: (state, action) => {
      state.currentBoardLoading = action.payload;
    },
    setCurrentBoardData: (state, action) => {
      state.currentBoardData = action.payload;
    },

    setQuestionsLoading: (state, action) => {
      state.questionsLoading = action.payload;
    },
    setQuestionsData: (state, action) => {
      state.questionsData = action.payload;
    },
    setQuotaQuestionsStatsLoading: (state, action) => {
      state.quotaQuestionsStatsLoading = action.payload;
    },
    setQuotaQuestionsStatsData: (state, action) => {
      state.quotaQuestionsStatsData = action.payload;
    },
    setClientFwBoardLoading: (state, action) => {
      state.clientFwBoardLoading = action.payload;
    },

    setClientFwBoardData: (state, action) => {
      state.clientFwBoardData = action.payload;
    },
  },
});

export const {
  setIsFieldworkLoading,
  setFieldworkDataSource,
  setIsCardStatsLoading,
  setCardsStatsData,
  setIsSurveyProgressLoading,
  setSurveyProgressData,
  setIsTotalCompleteLoading,
  setTotalCompleteData,
  setIsSurveyPrgressLoading,
  setIsSurveyProductLoading,
  setSurveyProductData,
  setCurrentBoardLoading,
  setCurrentBoardData,

  setQuestionsLoading,
  setQuestionsData,
  setQuotaQuestionsStatsLoading,
  setQuotaQuestionsStatsData,
  setClientFwBoardLoading,
  setClientFwBoardData,
} = fieldworkBoardSlice.actions;
export default fieldworkBoardSlice.reducer;
