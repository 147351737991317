import { createSlice } from "@reduxjs/toolkit";

const boardSlice = createSlice({
  name: "boards",
  initialState: {
    isLoading: false,
    dataSource: [],
    fieldWork: false,
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setDataSource: (state, action) => {
      state.dataSource = action.payload;
    },
    setFieldWork: (state, action) => {
      state.fieldWork = action.payload;
    },
  },
});

export const { setIsLoading, setDataSource, setFieldWork } = boardSlice.actions;
export default boardSlice.reducer;
