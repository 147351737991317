import { Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Loading from "@/components/Loading";
import "antd/dist/antd.css";
import "./i18n";
import "./App.less";
import Theme from "@/containers/Theme";
import RoutePublic from "@/containers/RoutePublic";
import RoutePrivate from "@/containers/RoutePrivate";

const Welcome = lazy(() => import("@/pages/Welcome"));
const Dashboard = lazy(() => import("@/pages/Dashboard"));
const ProjectBoard = lazy(() => import("@/pages/ProjectBoard"));
const ProjectBoardCreate = lazy(() => import("@/pages/ProjectBoardCreate"));
const DataBoard = lazy(() => import("@/pages/DataBoard"));
const FieldWorkBoard = lazy(() => import("@/pages/FieldWorkBoard"));
const ClientFwBoard = lazy(() => import("@/pages/ClientFwBoard"));

function App() {
  return (
    <Router>
      <Theme>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route
              exact
              path="/daniel"
              component={() => {
                window.location.href =
                  "https://business.flavorwiki.com/book-meeting";
                return null;
              }}
            />
            <RoutePublic exact path="/">
              <Welcome />
            </RoutePublic>
            <RoutePublic exact path="/client-fw-board/:id">
              <ClientFwBoard />
            </RoutePublic>
            <RoutePrivate path="/lite-userbase">
              <Dashboard />
            </RoutePrivate>
            <RoutePrivate path="/create/project-board">
              <ProjectBoardCreate />
            </RoutePrivate>
            <RoutePrivate path="/create/field-work-board">
              <ProjectBoardCreate fieldwork={true} />
            </RoutePrivate>
            <RoutePrivate path="/project-board/:id">
              <ProjectBoard />
            </RoutePrivate>

            <RoutePrivate path="/field-work-board/:id">
              <FieldWorkBoard />
            </RoutePrivate>
            <RoutePrivate path="/data-board">
              <DataBoard />
            </RoutePrivate>
          </Switch>
        </Suspense>
      </Theme>
    </Router>
  );
}

export default App;
